var page_home_loaded = 1;
global.page_home_loaded = page_home_loaded;

// var smInit = require('scrollmagic'),
//     animation = require('../../../node_modules/scrollmagic/scrollmagic/minified/plugins/animation.gsap.min.js'),
//     tmInit = require('../../../node_modules/gsap/src/minified/TimelineMax.min.js');

page_home = {
	load: function(){
		
	}
}

$(window).on('load', function(){
	page_home.load();
})